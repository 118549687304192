/* ProgressBar.css */
.progress-animation {
  transform: translateX(0);
}

@keyframes progressAnimation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.progress-animation {
  animation: progressAnimation 1s ease-out forwards;
}
