.date-picker {
  .rdrDays {
    .rdrDayDisabled {
      background-color: transparent;
    }
  }
  .disable-next-month .rdrNextButton {
    cursor: not-allowed;
    opacity: 50%;
  }
}
