@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'DM Sans', 'sans-serif';

  .no-spinners {
    -moz-appearance: textfield;
  }

  .no-spinners::-webkit-outer-spin-button,
  .no-spinners::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer base {
  :root {
    --color-theme: #ffffff;
    --color-theme20: #ffffff33;
    --color-bgBase: #f7f9fb;
    --color-content: #1c1c1d;
    --color-content1: #101928;
    --color-content2: #101828;
    --color-content3: #060606;
    --color-contentLite: #1e2f3f;
    --color-subText: #9c9c9c;
    --color-grey: #7e7e7e;
    --color-grey3: #e6e7e7;
    --color-grey90: #e8eaeb;
    --color-grey80: #6d6d6d;
    --color-grey70: #7e767616;
    --color-grey60: #98a2b3;
    --color-grey50: #e9e9e9;
    --color-grey40: #d9d9d9;
    --color-grey30: #e4e4e4;
    --color-grey20: #f7f9fb;
    --color-grey10: #f4f4f4;
    --color-primary: #00a657;
    --color-primary5: #e5fff3;
    --color-primary10: #e5f5ec;
    --color-primary20: #00a65733;
    --color-primary30: #166021;
    --color-primary40: #47b881;
    --color-primary50: #0f973d;
    --color-primary60: #097140;
    --color-primary70: #0c9d61;
    --color-primary80: #55d38f;
    --color-primary90: #036b26;
    --color-primaryDark: #056c3b;
    --color-primaryLite: #d7f1eb;
    --color-primaryLiteBg: #f1faf8;
    --color-primaryLite1: #eefffb;
    --color-primaryLite2: #e8f7f3;
    --color-primaryLite3: #00a6571a;
    --color-primaryLite4: #e9fff1;
    --color-primaryLite5: #daf2e3;
    --color-primaryLite6: #16602133;
    --color-primaryLite7: #d1eee7;
    --color-primaryLite8: #f1fcf9;
    --color-primaryLite9: #ddf5eb;
    --color-primaryLite10: #e9fff4;
    --color-error: #e60000;
    --color-error5: #ffebeb;
    --color-error10: #ffebee;
    --color-error20: #cb1a14;
    --color-error30: #fff8f8;
    --color-error40: #eb6f70;
    --color-error50: #e76262;
    --color-errorLite: #fce0e0;
    --color-errorLite1: #c9412233;
    --color-errorLite2: #c94122;
    --color-errorLite3: #ee0000;
    --color-errorLite4: #fbdfdf;
    --color-errorLite5: #c90707;
    --color-disabledContent: #8c8c8c;
    --color-borderGrey: #4e4e4e;
    --color-borderGrey1: #f1f1f1;
    --color-borderGrey2: #d6d6d6;
    --color-borderGrey3: #e4e7ec;
    --color-borderGrey4: #f0f2f5;
    --color-borderGrey5: #d0d5dd;
    --color-borderGrey6: #d2d5dd;
    --color-bgGrey: #262628;
    --color-bgGrey10: #f2f2f2;
    --color-bgGrey20: #f6f6f6;
    --color-bgGrey30: #adadad;
    --color-bgGrey40: #dbdbdb;
    --color-bgGrey50: #6c6869;
    --color-bgGrey60: #dadadb;
    --color-bgGrey70: #fbfbfb;
    --color-bgGrey80: #bfbfbf;
    --color-bgGrey90: #d9d9d933;
    --color-neutral: #707273;
    --color-greyStroke: #e2e2e2;
    --color-greyBorder: #efefef;
    --color-transparentGrey: #f3f3f3e5;
    --color-verifiedGreen: #166021;
    --color-darkGrey: #333333;
    --color-greyText1: #1c1c1c;
    --color-selectionPrimary: #bfeadf;
    --color-whiteBorder: #f7f7f7;
    --color-yellow: #ffa412;
    --color-yellow10: #fff7e1;
    --color-yellow20: #ffad0d;
    --color-yellow30: #f9cf4a;
    --color-yellow40: #eca336;
    --color-yellow50: #ebb376;
    --color-yellow60: #e2990b;
    --color-yellowLite: #ffa41233;
    --color-yellowLite3: #e2ad3a26;
    --color-yellowLite2: #ffad0d1a;
    --color-yellowLite3: #fef08a33;
    --color-yellowLite1: #fff7e7;
    --color-grey1: #f9f9f9;
    --color-grey2: #a8a8a8;
    --color-blueGrey: #6d7a96;
    --color-warningYellow: #dd900d;
    --color-warning500: #ffc62b;
    --color-meroon: #913c39;
    --color-blue: #f1f8ff;
    --color-blue1: #4ba1ff;
    --color-blue2: #f8fcff;
    --color-blue3: #3b82f6;
    --color-blue4: #f3f7ff;
    --color-blue5: #a1d0ff;
    --color-blue6: #78a3e8;
    --color-blue7: #ddeafe;
    --color-pink10: #e49d5f;
    --color-pink20: #e49d5f33;
    --color-brown10: #854d0e;
    --color-gold10: #e2de86;
    --color-gold20: #e3de86;
    --color-gold30: #fae7c4;
    --color-warningBg: #fffbf3;
    /* ... */
  }

  :root[theme='dark'] {
    --color-theme: #1e1e1e;
    --color-bgBase: #1c1c1d;
    --color-content: #d9d9d9;
    /* ... */
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  white-space: pre-wrap;
  padding: 0 24px;
}

@media (min-width: 640px) {
  .container {
    max-width: 540px;
    padding: 0 16px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 644px;
  }
}

@media (min-width: 860px) {
  .container {
    max-width: 736px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: unset;
    padding: 0 50px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1056px;
    padding: 0 16px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1240px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1312px;
  }
}

@media (min-width: 1680px) {
  .container {
    max-width: 1456px;
  }
}

@media (min-width: 1780px) {
  .container {
    max-width: 1610px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1750px;
  }
}

.page {
  padding: 24px 0px;
}

.section {
  padding: 32px;
  border-radius: 12px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #7e7e7e;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 100px;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #00a657 !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 100px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 100px;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #d2d2d2;
  border-radius: 100px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 100px;
}

.card-shadow {
  box-shadow: 0px 0px 7px 0px #0000001a;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

#branch-dropdown-items::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

#branch-dropdown-items::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 100px;
  min-height: 40px;
}

#branch-dropdown-items::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}
